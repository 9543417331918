<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import appConfig from "@/app.config.json";
import axios from "axios";
import errorHandling from "../../../mixins/errorHandling";

/**
 * Advanced table component
 */
export default {
  mixins: [errorHandling],
  page: {
    title: "Transactions",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      members: [],
      groupID: this.$route.params.id,
      title: "Group Members",
      items: [
        {
          text: "Agent",
          href: "/agent/groups",
        },
        {
          text: "Group Members",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [20, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "displayName", sortable: true },
        { key: "accountNo", sortable: true },
        { key: "mobileNo", sortable: true },
        { key: "gender.name", sortable: true, label: "Gender" },
        { key: "officeName", sortable: true },
        { key: "id", label: "View Loans" },
      ],
      loading: false,
      filterStatus: "active",
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.members.length;
    },
    filteredMembers() {
      if (this.filterStatus === "active") {
        return this.members.filter((member) => member.active === true);
      } else if (this.filterStatus === "inactive") {
        return this.members.filter((member) => member.active === false);
      } else {
        return this.members;
      }
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getGroupMembers();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    showActiveMembers() {
      this.filterStatus = "active";
    },
    showInactiveMembers() {
      this.filterStatus = "inactive";
    },

    showAllMembers() {
      this.filterStatus = null;
    },
    async getGroupMembers() {
      this.loading = true;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/agent/get-group-members/${this.$route.params.id}`
        );
        if (response.status === 200 || response.status === 201) {
          this.members = response.data.data.clientMembers;
          this.loading = false;
        } else {
          this.loading = false;
          this.throwError();
        }
      } catch (error) {
        this.handleError(error.response.data.message);
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="loading">
      <b-spinner class="m-2 m-auto" variant="primary" role="status"></b-spinner>
    </div>
    <div class="row" v-else>
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Group Members Table</h4>
            <div class="mt-4 mb-4 row">
              <div class="col-4">
                <b-button
                  variant="secondary"
                  @click.prevent="showInactiveMembers"
                >
                  <i class="ri-user-unfollow-line align-middle mr-2"></i> Show
                  Inactive Members
                </b-button>
              </div>
              <div class="col-4">
                <b-button
                  variant="secondary"
                  @click.prevent="showActiveMembers"
                >
                  <i class="ri-user-follow-line align-middle mr-2"></i> Show
                  Active Members
                </b-button>
              </div>

              <div class="col-4">
                <b-button variant="secondary" @click.prevent="showAllMembers">
                  <i class="ri-team-line align-middle mr-2"></i> Show All
                  Members
                </b-button>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 pointer-cursor-table">
              <b-table
                :items="filteredMembers"
                :fields="fields"
                responsive="sm"
                stacked="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                hover
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template v-slot:cell(id)="row">
                  <div class="row">
                    <div class="mr-1">
                      <router-link
                        :to="`/agent/customer/transactions/${row.value}`"
                      >
                        <b-button variant="warning">
                          <i class="ri-eye-line align-middle mr-2"></i> View
                          Transactions
                        </b-button>
                      </router-link>
                    </div>
                    <div class="ml-1">
                      <router-link
                        :to="`/agent/verify-group-loan/${groupID}/${row.value}`"
                      >
                        <b-button variant="success">
                          <i class="ri-eye-line align-middle mr-2"></i> View
                          Accounts
                        </b-button>
                      </router-link>
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.pointer-cursor-table tbody tr:hover {
  cursor: pointer;
}
</style>
